import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Internship from './components/Internship';
import FulltimeJobsPage from './FulltimeJobsPage';
import OtherJobs from './components/OtherJobs';
import Applied from './components/Applied';
import Dashboard from './Dashboard';
import JobDetailsPage from './JobDetailsPage';
import InternshipDetailsPage from './components/InternshipDetailsPage';
import SignUp from './Signup';
import Hirespoofbot from './components/Hirespoofbot';
import Login from './Login';
import ReferAndEarn from './components/ReferAndEarn';
import { CoinProvider } from './components/CoinContext';
import './App.css';
import ContactUs from './components/ContactUs';
import Disclaimer from './components/Disclaimer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import AboutUs from './components/AboutUs';
import AdSenseComponent from './components/AdSenseComponent';
import CombinedPage from './components/CombinedPage';
import Pop from './components/Pop';
import Home from './components/Home';
import ColdMailTemplatesPage from './components/ColdMailTemplatesPage';
import PersonDetails from './components/PersonDetails';
import ResumeScan from './components/ResumeScan';
import AtsAnalysis from './components/ATSResumeScore';
import HrEmailList from './components/HrEmailList';
import PaymentPage from './components/PaymentPage';
import TawkToChat from './components/TawkToChat'; // Import TawkToChat component

function App() {
  return (
    <CoinProvider>
      <Router>
        <div className="App">
          <div className="content">
            <Routes>
              <Route path="/referral" element={<Home />} />
              <Route path="/details/:id" element={<PersonDetails />} />
              <Route exact path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/fulltime-jobs" element={<FulltimeJobsPage />} />


              
              <Route path="/internships" element={<Internship />} />
              <Route path="/other-jobs" element={<OtherJobs />} />
              <Route path="/applied" element={<Applied />} />
              <Route path="/job-details/:id" element={<JobDetailsPage />} />
              <Route path="/internship-details/:id" element={<InternshipDetailsPage />} />
              <Route path="/refer-and-earn" element={<ReferAndEarn />} />
              <Route path="/hirespoofbot" element={<Hirespoofbot />} />
              <Route path="/contact-us" element={<ContactUs />} />


              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-condition" element={<TermsAndConditions />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/combined" element={<CombinedPage />} />
              <Route path="/resume-scan" element={<ResumeScan />} />
              <Route path="/ats-score" element={<AtsAnalysis />} />
              <Route path="/hr-emails" element={<HrEmailList />} />
              <Route path="/templates" element={<ColdMailTemplatesPage />} />
              <Route path="/payment" element={<PaymentPage />} />
            </Routes>
            <AdSenseComponent />
            <Pop />
            <TawkToChat /> {/* Add the Tawk.to chat widget */}
          </div>
        </div>
      </Router>
    </CoinProvider>
  );
}

export default App;
