// TawkToChat.js
import React, { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    // Dynamically add the Tawk.to script
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/673b23962480f5b4f59fd460/1icvg616r";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return null; // No visible UI needed for the chat script
};

export default TawkToChat;
